import * as React from "react";
import { graphql } from "gatsby";
import { differenceInCalendarDays, addDays, format } from "date-fns"; // @TODO: Keep format; use for fitplanValidUntil.
import { css } from "@emotion/core";
import PageWrapper from "../components/pageWrapper/PageWrapper";
import Icon from "../components/icons";
import Redirect from "../components/Redirect";
import localize from "../hoc/Localize";
import "./account.scss";
import Card from "./account/card";
import MealplanIcon from "./account/mealplan-icon";
import FitplanIcon from "./account/fitplan-icon";
import AccountLayout from "./account/account-layout";
import InternalPage from "./account/internal-page";
import ProfileImage from "./account/ProfileImage";
import { useAuthContext } from "@fitplan/context/lib-es/auth";
import { baseTextStyle } from "@fitplan/lib/components/NewSubscriptionModal/elements";

export interface Props {
    data: any;
}

const Account: React.FunctionComponent<Props> = props => {
    const authContext = useAuthContext();
    const { loggedIn, user } = authContext;
    // useMount(() => {
    //     if (!loggedIn) {
    //         redirectTo("/login");
    //     }
    // });
    if (!loggedIn) {
        return <Redirect to="/login" />;
    }

    const profilePic = user.profileImage;
    const accountData = props.data.sanityAccount.account;
    const expiration = new Date(user.displayExpirationTimeStamp).toDateString();
    const StoreType =
        user.paymentStoreType == 1
            ? "IOS Premium account"
            : user.paymentStoreType == 2
            ? "Google Premium account"
            : user.paymentStoreType == 99
            ? "Ultra Premium"
            : "Web Premium Account"; // 1 apple 2 google 3 stripe
    // Days since joining Fitplan.
    const { dateJoined } = user;
    const daysOnFitplan = differenceInCalendarDays(
        new Date(),
        new Date(dateJoined)
    );

    const userName = `${user.firstName ? user.firstName : ""}${
        user.lastName ? `  ${user.lastName}` : ""
    }`;
    let fitplanValidUntil = "Free User";
    if (user.paymentExpirationTimestamp != 0) {
        const today = new Date();
        const daysDiff = differenceInCalendarDays(
            new Date(user.displayExpirationTimeStamp),
            today
        );

        const expires = format(addDays(today, daysDiff), "MMMM d, yyyy");
        if (daysDiff < 0) {
            fitplanValidUntil = `Expired on ${expires}.`;
        } else {
            fitplanValidUntil = `Valid until ${expires}`;
        }
    }

    const isMealplanMember = user.isMealplanMember;
    const isFitplanMember =
        user.paymentStoreType !== 0 && user.paymentExpirationTimestamp !== 0;

    return (
        <PageWrapper {...props} className="account-container">
            <AccountLayout currentPage="/account">
                <Card
                    mainLinkLabel={accountData.accountNavbar.editProfile}
                    className="wide_card"
                    mainLink="/account/profile"
                    mainLinkIcon="pencil"
                >
                    <div
                        css={css`
                            display: grid;
                            grid-template-rows: 40% 60%;
                            @media (min-width: 900px) {
                                margin-top: -18px;
                                display: flex;
                            }
                        `}
                    >
                        <div
                            css={css`
                                width: 236px;
                            `}
                        >
                            <ProfileImage size={120} imageUrl={profilePic} />
                            <h2
                                css={css`
                                    ${baseTextStyle}
                                    font-size: 24px;
                                    font-weight: bold;
                                    font-stretch: condensed;
                                    line-height: 1.25;
                                    margin-bottom: 16px;
                                    margin-top: 14px;
                                `}
                            >
                                {userName}
                            </h2>
                            <p css={baseTextStyle}>
                                {daysOnFitplan} Day
                                {daysOnFitplan !== 1 ? "s" : ""} on Fitplan
                                {/* ADD THIS BACK ONCE DATA IS AVAILABLE */}
                                {/*  <br />
                    {fitplansCompleted} Fitplans Completed */}
                            </p>
                        </div>
                        <div
                            css={css`
                                @media (max-width: 900px) {
                                    display: flex;
                                    flex-direction: column;
                                }
                                margin-bottom: 50px;
                                p {
                                    ${baseTextStyle}
                                    font-size: 12px;
                                    margin-top: 24px;
                                    min-width: 250px;
                                    @media (max-width: 350px) {
                                        min-width: 200px;
                                    }
                                }
                                input {
                                    ${baseTextStyle}
                                    border: 0;
                                    margin-top: 8px;
                                    min-width: 270px;
                                    overflow-x: hidden;
                                    background: none;
                                    @media (max-width: 350px) {
                                        min-width: 200px;
                                    }
                                }
                            `}
                        >
                            <p>{accountData.name}</p>
                            <input value={userName} disabled={true} />
                            <p>{accountData.email}</p>
                            {/* @TODO: verify this will always be an email address or not */}
                            <input value={user.username} disabled={true} />{" "}
                            <p>{accountData.gender}</p>
                            <input value={user.gender} disabled={true} />{" "}
                            {/* @TODO: need lookup for gender display text */}
                            <p>{accountData.password}</p>
                            <input value="••••••••" disabled={true} />
                        </div>
                    </div>
                </Card>
                <Card
                    titleText="Fitplan"
                    className="wide_card"
                    SVGIcon={<FitplanIcon width="40px" />}
                    mainLink="/account/subscription"
                    mainLinkLabel={accountData.manageSubscription}
                    mainLinkIcon="credit-card-icon"
                >
                    <div
                        css={css`
                            display: flex;
                            height: 70px;
                            width: 236px;
                            margin-top: 20px;
                            margin-bottom: 40px;
                            @media (max-width: 768px) {
                                display: block;
                                height: 134px;
                                width: 100%;
                            }
                        `}
                    >
                        {isFitplanMember ? (
                            <div
                                css={css`
                                    display: flex;
                                `}
                            >
                                <svg
                                    viewBox="0 0 42 38"
                                    css={css`
                                        width: 26px;
                                        height: 24px;
                                        margin-bottom: -5px;
                                    `}
                                >
                                    <g
                                        fill="none"
                                        fillRule="evenodd"
                                        stroke="#12B587"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                    >
                                        <path d="M36.65 16.17c.14.899.212 1.823.212 2.761 0 9.903-8.028 17.931-17.931 17.931S1 28.834 1 18.931 9.028 1 18.931 1c2.985 0 5.799.728 8.276 2.02" />
                                        <path d="M10.655 14.793l8.276 8.276L41 1" />
                                    </g>
                                </svg>
                                <span
                                    css={css`
                                        margin-left: 16px;
                                        ${baseTextStyle}
                                        font-stretch: condensed;
                                        line-height: 1.14;
                                        font-weight: bold;
                                        @media (min-width: 350px) {
                                            width: 200px;
                                        }
                                        @media (max-width: 768px) {
                                            margin-bottom: 20px;
                                        }
                                    `}
                                >
                                    {accountData.member}
                                </span>
                            </div>
                        ) : (
                            <div
                                css={css`
                                    display: flex;
                                `}
                            >
                                <svg
                                    viewBox="0 0 42 38"
                                    css={css`
                                        width: 26px;
                                        height: 32px;
                                        margin-bottom: -5px;
                                    `}
                                >
                                    <g
                                        fill="none"
                                        fill-rule="evenodd"
                                        stroke="#FC172F"
                                        stroke-linecap="square"
                                        stroke-width="2"
                                        transform="translate(1 1.603)"
                                    >
                                        <path d="M28 12L12 28M28 28L12 12" />
                                        <circle cx="20" cy="20" r="20" />
                                    </g>
                                </svg>
                                <span
                                    css={css`
                                        margin-left: 16px;
                                        ${baseTextStyle}
                                        font-weight: bold;
                                        font-stretch: condensed;
                                        line-height: 1.14;

                                        @media (min-width: 350px) {
                                            width: 200px;
                                        }
                                    `}
                                >
                                    {accountData.nonMember}
                                </span>
                            </div>
                        )}
                        <div
                            css={css`
                                width: 300px;
                                @media (max-width: 768px) {
                                    width: 100%;
                                }
                                p {
                                    ${baseTextStyle}
                                    white-space: nowrap;
                                    margin-bottom: 8px;
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                }
                            `}
                        >
                            <p>Type: {StoreType}</p>
                            {StoreType != "Ultra Premium" ? (
                                <p>{fitplanValidUntil}</p>
                            ) : (
                                ""
                            )}
                            {user &&
                            user.giftCards &&
                            user.giftCards.length > 0 ? (
                                <div
                                    css={css`
                                        ${baseTextStyle}
                                        font-weight: bold;
                                        white-space: nowrap;
                                        margin-bottom: 8px;
                                        text-overflow: ellipsis;
                                    `}
                                >
                                    Redeemed Gift Cards:{" "}
                                    {user.giftCards.map(giftCard => (
                                        <span
                                            css={css`
                                                ${baseTextStyle}
                                                border: 1px solid #ccc;
                                                border-radius: 2px;
                                                padding: 4px;
                                                margin: 0 4px;

                                                :hover {
                                                    border: 1px solid #111213;
                                                }
                                            `}
                                        >
                                            {giftCard.giftCardCode}
                                        </span>
                                    ))}
                                    {(user.paymentStoreType === 1 ||
                                        user.paymentStoreType === 2) && (
                                        <div
                                            css={css`
                                                margin-top: 8px;
                                                font-weight: normal;
                                            `}
                                        >
                                            Fitplan subscribers who redeem a
                                            Gift Card must manage their
                                            subscription in the{" "}
                                            {user.paymentStoreType === 1
                                                ? "Apple App Store"
                                                : user.paymentStoreType === 2
                                                ? "Google Play Store"
                                                : ""}{" "}
                                            to avoid recurring charges.
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div />
                            )}
                        </div>
                    </div>{" "}
                </Card>
                <Card
                    titleText="Mealplan"
                    SVGIcon={<MealplanIcon width="40px" />}
                    mainLinkLabel={
                        !isMealplanMember ? "Subscribe to Mealplan" : null
                    }
                    mainLink={
                        !isMealplanMember ? "https://www.mealplan.com" : null
                    }
                    mainLinkIcon={!isMealplanMember ? "credit-card-icon" : null}
                >
                    <div className="pt-4 flex flex-no-wrap">
                        {isMealplanMember ? (
                            <Icon
                                type="faHeart"
                                css={css`
                                    color: #f00;
                                `}
                            />
                        ) : (
                            <svg
                                width="42"
                                height="43"
                                viewBox="0 0 42 43"
                                css={css`
                                    width: 24px;
                                    height: 23px;
                                    margin-bottom: -6px;
                                `}
                            >
                                <g
                                    fill="none"
                                    fillRule="evenodd"
                                    stroke="#FC172F"
                                    strokeLinecap="square"
                                    stroke-width="2"
                                    transform="translate(1 1.603)"
                                >
                                    <path d="M28 12L12 28M28 28L12 12" />
                                    <circle cx="20" cy="20" r="20" />
                                </g>
                            </svg>
                        )}
                        <p
                            className="inline ml-4 mb-8"
                        >
                            {!isMealplanMember ? "NOT A " : ""}MEALPLAN MEMBER
                        </p>
                    </div>
                </Card>
            </AccountLayout>
        </PageWrapper>
    );
};

export const query = graphql`
    query accountQuery {
        sanityAccount {
            account {
                header {
                    _type
                    en
                    es
                }
                question {
                    _type
                    en
                    es
                }
                ready {
                    _type
                    en
                    es
                }
                download {
                    _type
                    en
                    es
                }
                progress {
                    _type
                    en
                    es
                }
                member {
                    _type
                    en
                    es
                }
                nonMember {
                    _type
                    en
                    es
                }
                joinFitplan {
                    _type
                    en
                    es
                }
                joinFacebook {
                    _type
                    en
                    es
                }
                overview {
                    _type
                    en
                    es
                }
                profile {
                    _type
                    en
                    es
                }
                email {
                    _type
                    en
                    es
                }
                password {
                    _type
                    en
                    es
                }
                hiddenPassword {
                    _type
                    en
                    es
                }
                name {
                    _type
                    en
                    es
                }
                gender {
                    _type
                    en
                    es
                }
                memberSince {
                    _type
                    en
                    es
                }
                editProfile {
                    _type
                    en
                    es
                }
                manageSubscription {
                    _type
                    en
                    es
                }
                accountNavbar {
                    accountOverview {
                        _type
                        en
                        es
                    }
                    editProfile {
                        _type
                        en
                        es
                    }
                    subscription {
                        _type
                        en
                        es
                    }
                }
                subscriptionCancelButton {
                    buttonText {
                        _type
                        en
                        es
                    }
                    unsubscribeAppleCode
                    unsubscribeGoogleCode
                    title {
                        _type
                        en
                        es
                    }
                    info {
                        _type
                        en
                        es
                    }
                    question {
                        _type
                        en
                        es
                    }
                    cancelButton {
                        _type
                        en
                        es
                    }
                    confirmButton {
                        _type
                        en
                        es
                    }
                }
            }
        }
    }
`;

export default localize(Account);

import * as React from 'react';

const FitplanIcon = ({
  style = { height: '41px' },
  width = '40px',
  height = '41px',
  className = '',
  viewBox = '0 0 40 41',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    className={`svg-icon ${className || ''}`}
  >
    <defs>
      <path id="a" d="M0 0h40v40H0z" />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(0 .576)">
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <path
        fill="#111213"
        d="M35.268 40H4.732A4.732 4.732 0 0 1 0 35.268V4.732A4.732 4.732 0 0 1 4.732 0h30.536A4.732 4.732 0 0 1 40 4.732v30.536A4.732 4.732 0 0 1 35.268 40"
        mask="url(#b)"
      />
      <g fill="#FFF" mask="url(#b)">
        <path d="M8.501 24.51l-1.293 6.146h7.145l1.293-6.145zM10.105 16.862l-1.293 6.146h14.024l1.294-6.146zM11.726 9.214l-1.294 6.146H31.45l1.293-6.146z" />
      </g>
    </g>
  </svg>
);

export default FitplanIcon;
